<template>
  <el-dialog :visible.sync="dialog" title="查询ERP编码" append-to-body :close-on-click-modal="false" @closed="handleClosed" width="1080px">
    <div class="head-container">
      <el-input v-model="query.code" :maxlength="50" placeholder="输入ERP编码搜索" style="width: 180px;" />&nbsp;
      <el-input v-model="query.name" :maxlength="20" placeholder="输入商品名称搜索" style="width: 180px;" />&nbsp;
      <el-input v-model="query.specs" :maxlength="50" placeholder="输入规格搜索" style="width: 180px;" />&nbsp;
      <el-date-picker v-model="query.dateRange" type="daterange" style="width: 230px;" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" />&nbsp;
      <el-button size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
    </div>
    <el-table ref="list" :data="data" row-key="id" border v-loading="loading" highlight-current-row empty-text="没有搜索到结果" @current-change="handleCurrentChange" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="45" reserve-selection v-if="isImport" />
      <el-table-column prop="erpCode" label="ERP编码" min-width="120" />
      <el-table-column prop="name" label="商品名称" min-width="240" />
      <el-table-column prop="specs" label="规格" min-width="120" :formatter="$goodsSpecConvert" />
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes,jumper" @size-change="sizeChange" @current-change="pageChange" />
    <div slot="footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button type="primary" :disabled="isImport ? (!selection || !selection.length) : !selected" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import initData from "@/mixins/initData";
import request from "@/utils/request";

export default {
  mixins: [initData],
  props: {
    isImport: Boolean
  },
  data() {
    return {
      dialog: false,
      selected: null,
      selection: null,
      query: {
        code: null,
        name: null,
        spec: null
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近两年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 2);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/goodsByErp";
      this.params = this.query;
      return true;
    },
    afterLoad(res) {
      let goods = [];
      res.forEach(o => {
        let co = JSON.parse(JSON.stringify(o));
        delete co.skus;
        (o.skus || []).forEach(s => {
          s.goods = co;
          goods.push(s);
        });
      });
      return goods;
    },
    handleClosed() {
      this.selected = null;
      if (this.$refs.list) {
        this.$refs.list.setCurrentRow();
        this.$refs.list.clearSelection();
      }
    },
    handleSubmit() {
      if (this.isImport) {
        this.$emit("import", this.selection);
      } else {
        this.$emit("select", this.selected);
      }
      this.dialog = false;
    },
    handleCurrentChange(row) {
      this.selected = row;
    },
    handleSelectionChange(sels) {
      this.selection = sels;
    },
    show() {
      this.dialog = true;
    }
  },
  mounted() {
    this.init();
  }
};
</script>